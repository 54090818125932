import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';
import SEO from '../components/App/SEO';
import metaImage from '../assets/images/top-banner-op-image.png';

const TermsOfService = () => {
    return (
        <Layout>
            <Navbar />
            <SEO
              title="SNSキャンペーンツール｜利用規約"
              desc="ウェブサービスである「リタッチ」（以下「本サービス」といいます。）を運営する株式会社リタッチ（以下「当社」といいます。）は、本サービスのユーザー（以下「ユーザー」という）のプライバシーを尊重し、ユーザーの個人情報およびその他のユーザーのプライバシーに係る情報（以下「プライバシー情報」といいます。）の管理に細心の注意を払います。"
              banner={metaImage}
              type={'Article'}
              pathname={'/terms-of-service'}
              shortName="利用規約｜リタッチ"
              createdAt="2021-03-02"
            />
            <PageBanner
                pageTitle="利用規約"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="利用規約"
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="">
                                <p>本規約は、株式会社リタッチ（以下「当社」といいます。）が運営するWEBサービスである「リタッチ」（以下「本サービス」といいます。）の利用条件等を定めるものです。<br/>
                                本規約は、本サービスにアカウント登録するユーザー（以下「ユーザー」といいます。）と当社との間の一切の関係に適用されます。<br />
                                なお、本規約に基づいて当社とユーザーとの間で締結される本サービスの利用に係る契約を「利用契約」とします。</p>
                                <h3>第１章　総則</h3>
                                <h4>第１条（本サービスの概要）</h4>
                                <p>
                                <ol>
                                    <li>ユーザーは、本サービスを介してオリジナルのキャンペーンやインスタントウィン、コンテスト等の様々なプロモーション（以下「プロモーション等」といいます。）を作成してそれらを自身の運営するWEBサイト等にて実施できます。</li>
                                    <li>本サービスは、原則として有料にて提供されますが、一部のプランでは、無料にて利用することができます。ユーザーは、本サービスにログインするための電子メールアドレスおよびパスワード（以下「アカウント」といいます。）を使用して本サービスを利用するものとします。</li>
                                </ol>
                                </p>
                                <h4>第２条（本規約への同意、適用範囲）</h4>
                                <p>
                                <ol>
                                    <li>ユーザーは、本サービスの利用に際して、本規約のすべての条項に同意し、それらを遵守しなければなりません。</li>
                                    <li>当社が本サービスの提供に際してユーザーに提示するガイドライン、メール等にて通知される重要事項等（プライバシーポリシーを含むものとし、以下「ガイドライン等」といいます。）は、本規約の一部を実質的に構成するものとします。</li>
                                    <li>当社は、ユーザーが本サービスを利用したことをもってユーザーが本規約のすべての条項に同意したものとみなします。</li>
                                    <li>本規約および本サービスの提供に際して使用される「時間」は、すべて日本時間（GMT＋9：00）を基準とします。</li>
                                </ol>
                                </p>
                                <h4>第３条（本規約の変更）</h4>
                                <p>
                                <ol>
                                    <li>当社は、ユーザーに対して、事前に何らの通知を行うことなく、本規約を変更することができます。</li>
                                    <li>ユーザーは、本サービスを利用するごとに本サイト上に掲載される本規約を確認するものとします。当社は、本規約の変更後、ユーザーが本サービスを利用したことをもって、ユーザーが本規約の変更を確認し、同意したものとみなすことができます。</li>
                                </ol>
                                </p>
                                <h4>第４条（保証の否認）</h4>
                                <p>
                                <ol>
                                    <li>本サービスがユーザーの要求および利用目的に合致すること</li>
                                    <li>本サービスを介して提供されるすべての情報、画像、プログラムおよびその他のデータ等（以下「コンテンツ」といいます。）が正確であり、信頼できるものであること</li>
                                    <li>本サービスの通信等が中断されないこと</li>
                                    <li>本サービスを介して送受信した情報が所定の機器等に保存されること、正常に送受信されること、または画面上に表示されること</li>
                                    <li>本サービスが全ての機器やブラウザ等において正常に動作すること</li>
                                    <li>本サービスに係る情報等がすべての端末等において正常に表示されること</li>
                                    <li>本サービスのうちの第三者が提供するサービス（以下「外部サービス」といいます。）の機能等を使用して提供されるサービスについては、その外部サービスの仕様や内容の変更等に完全に対応すること</li>
                                    <li>本サービスで利用することで発生した、外部サービスのアカウントに対する責任を負うこと</li>
                                    <li>本サービス及び本サービスを介してユーザーが第３者に提供する全てのコンテンツの景品表示法及び薬事法への責任を負うこと</li>
                                </ol>
                                </p>
                                <h3>第２章　本サービスの利用契約</h3>
                                <h4>第５条（利用契約への申込、成立）</h4>
                                <p>
                                <ol>
                                    <li>本サービスの利用を希望する事業者等（以下「希望者」といいます。）は、当社が定める様式によって、希望するプランへの申込手続きをするものとします。なお、希望者は、利用申込の際に自身に帰属する有効な電子メールアドレスを指定しなければなりません。</li>
                                    <li>希望者は、利用申込に際して、正確、最新かつ真実の情報を入力しなければなりません。なお、当社は、必要に応じて、希望者に対して、身分証明書、登記事項証明書等の提示を要求できるものとします。</li>
                                    <li>当社は、利用申込を承認する場合、希望者に対して、承諾通知を送信します。なお、同承諾通知は、第１５条の手段によってなされるものとします。</li>
                                    <li>ユーザーは、前項の承諾通知の受領後、速やかに利用料金（第７条に規定されます。）を支払わなければなりません。</li>
                                    <li>ユーザーは、当社が利用料金の入金を確認後、正式にユーザーとなり、本サービスの利用を開始することができます。</li>
                                    <li>ユーザーが本サービスのプランのうち、無料で提供されるプランに申込をした場合は、第４項および前項の規定は適用されず、当社がその申込について承諾通知を送信した時点から本サービスを利用することができます。</li>
                                    <li>当社は、希望者が以下に該当する場合、当該申込を拒否することができます。
                                        <ul>
                                            <li>すでに本サービスのいずれかのプランを利用している場合</li>
                                            <li>過去に当社より何らかの処分等を受けたことがある場合</li>
                                            <li>未成年者である場合で、その親権者等から許諾を得ずに申込をしていたことが判明した場合</li>
                                            <li>暴力団や反社会的勢力、右左翼団体等の構成員であること、それらと密接な関係があること、およびそれらに資金等を提供していること等が判明した場合</li>
                                            <li>その他、当社がユーザーとして不適切であると判断した場合</li>
                                        </ul>
                                    </li>
                                </ol>
                                </p>
                                <h4>第６条（アカウント等の管理）</h4>
                                <p>
                                <ol>
                                    <li>ユーザーは、本サービスにログインするためのパスワードとして、第三者に推測されづらい文字列を指定しなければなりません。</li>
                                    <li>ユーザーは、本サービスのアカウントを厳重に管理しなければなりません。</li>
                                    <li>当社は、本サービスの利用に際してユーザーが入力したアカウントと第１項の登録されたアカウントとの一致をもって、当該利用がユーザー本人によるものとみなします。</li>
                                    <li>ユーザーは、 アカウントの管理不十分、使用上の過失または錯誤に起因する一切の損害を賠償する義務を負うものとします。</li>
                                    <li>ユーザーは、 何らかの事由によりアカウントの一部または全部の変更の必要性が生じた場合、当社が定める手段によってアカウントの再設定手続きをしなければなりません。</li>
                                    <li>ユーザーは、本サービスに登録された自身に関する情報（以下「登録情報」という）を正確且つ最新に保つ義務を負います。ユーザーは、登録情報に変更の必要が生じた場合、当社が定める手段により、速やかに登録情報の変更手続きをしなければなりません。</li>
                                </ol>
                                </p>
                                <h4>第７条（利用料金、支払い）</h4>
                                <p>
                                <ol>
                                    <li>本サービスの利用料金は、そのプランごとに定められるものとします。</li>
                                    <li>利用料金の支払いは、当社が定める手段によってなされるものとし、その支払いに銀行振込手数料等が発生する場合には、その手数料等を負担しなければなりません。</li>
                                    <li>当社は、ユーザーが既に支払った利用料金の返還を理由の如何を問わず一切しないものとします。</li>
                                    <li>当社は、利用料金の日割り計算を理由の如何を問わず一切しないものとします。</li>
                                </ol>
                                </p>
                                <h4>第８条（有効期間、更新）</h4>
                                <p>
                                <ol>
                                    <li>本サービスの有効期間は、ユーザーまたは当社からの特段の申し出がない限り、ユーザーが申込をしたプランごとに定める期間とします。なお、本サービスの有効期間の起算日は、第５条第５項および第６項の本サービスの利用開始日とします。</li>
                                    <li>本サービスの有効期間は、理由の如何を問わず、一切中断されないものとします。</li>
                                    <li>ユーザーは、利用中のプランの有効期間満了日までに希望するプランを選択し、該当する利用料金を支払うことにより、本サービスの利用を継続することができます。無料プランに変更する場合は、当該プランを選択することのみで本サービスの利用を継続することができます。</li>
                                    <li>前項の場合の本サービスの有効期間は、そのプランごとに定められる期間とし、その起算日は、継続前の期間満了日の翌日とします。</li>
                                    <li>ユーザーが本サービスの有効期間の継続の手続きをしなかった場合、本サービスの利用契約は、有効期間満了日の経過をもって終了します。</li>
                                </ol>
                                </p>
                                <h4>第９条（中途解約、プラン変更）</h4>
                                <p>
                                <ol>
                                    <li>ユーザーは、本サービスを有効期間の中途にて解約する場合、当社の定める手段によってその解約手続きをするものとします。なお、ユーザーが解約手続きをした場合、本サービスの利用契約は、その手続きの完了時点をもって終了します。</li>
                                    <li>ユーザーは、利用中のプランの有効期間中であっても、当社が定める手段により、そのプランを変更することができます。</li>
                                    <li>当社は、ユーザーが前項により利用するプランのアップグレードをする場合に限り、その重複分の期間について、変更前の利用料金額をユーザーに返金します。利用するプランのダウングレードをする場合は、重複分の利用料金があった場合でも返金等をしないものとします。</li>
                                    <li>ユーザーが利用中のプランをダウングレードする場合、当社は、そのダウングレードにより利用することができなくなるサービスに係るデータ等をすべて削除することができます。</li>
                                </ol>
                                </p>
                                <h4>第１０条（罰則規定）</h4>
                                <p>
                                <ol>
                                    <li>当社は、以下の各号のいずれかに該当するユーザーに対して、事前に通知することなくアカウントの一次利用停止、アクセスの一時遮断または本サービスの利用契約の解除等の処分を課すことができます。
                                        <ul>
                                            <li>第１４条に違反した場合</li>
                                            <li>当社および第三者の著作権、知的財産権、プライバシー等を侵害した場合</li>
                                            <li>本規約およびガイドライン等に違反した場合</li>
                                            <li>虚偽の情報等を申告、送信した場合</li>
                                            <li>本サービスの利用に関する重要事項について、当社からの指示等に従わない場合</li>
                                            <li>利用料金の支払いを遅滞または怠った場合</li>
                                            <li>ユーザーの信用状態、経済状態等が悪化したと判断するに足りる事由が発生した場合</li>
                                            <li>他のユーザー、応募者または第三者からクレーム等が頻発する場合で処分相当であると当社が判断した場合</li>
                                            <li>その他、当社がユーザーとして不適切と判断した場合</li>
                                        </ul>
                                    </li>
                                        <li>ユーザーが本サービスの利用契約の解除の処分を受けた場合、本サービスの利用契約は、その処分を受けた時点をもって終了します。</li>
                                        <li>当社は、ユーザーの悪質な本規約違反により本サービスの利用契約を解除した場合、当該ユーザーが実施中のプロモーション等への応募者に対して、その処分理由等を開示することができるものとします。</li>
                                </ol>
                                </p>
                                <h4>第１１条（本サービスの利用契約の終了時の措置等）</h4>
                                <p>
                                <ol >
                                    <li>当社は、本サービスの利用契約が何らかの事由で終了した場合、その手続きの完了時をもって当該ユーザーのダッシュボード（第１２条第２項に規定されます。）へのアクセスを遮断し、アカウントを利用停止にすることができます。</li>
                                    <li>当社は、本サービスの利用契約が終了した場合、その時点をもって当該ユーザーの登録情報、当該ユーザーが作成中または実施中のプロモーション等およびその他の当該ユーザーに係る情報の一部または全部を削除することができます。なお、当社は、それらの削除に起因して当該ユーザー、プロモーション等の応募者または第三者に損害が生じた場合でも、一切責任を負わないものとします。</li>
                                    <li>ユーザーは、自身の行為等に起因して当社または第三者に損害が生じた場合、本サービスの利用契約が終了した後であっても、すべての法的責任を負うものとします。</li>
                                </ol>
                                </p>
                                <h3>第３章　本サービス</h3>
                                <h4>第１２条（本サービスの利用条件等）</h4>
                                <p>
                                <ol>
                                    <li>ユーザーは、個人使用の目的の範囲内および自身のプロモーションの作成および実施の目的でのみ本サービスを利用することができます。</li>
                                    <li>ユーザーは、すべて自身の責任において本サービスを利用するものとします。</li>
                                    <li>当社は、ユーザーに対して、当該ユーザーのみが閲覧、編集等することのできる「ダッシュボード」を付与します。ユーザーは、ダッシュボードよりプロモーション等の作成や編集、管理等をすることができます。</li>
                                    <li>ユーザーは、本サービスを介して作成したプロモーション等およびその実施について、すべての責任を負うものとします。また、実施中のプロモーション等の内容変更やキャンセル等をする場合は、すべて自身の責任においてそれに伴う説明、クレーム対応、損害賠償等をしなければなりません。</li>
                                    <li>ユーザーは、当社が定める手段によってプロモーション等を作成、編集等するものとします。また、ユーザーは、作成したプロモーションを自身のWEBサイト、アプリ等に埋め込む場合、当社が推奨する環境を有するサーバー等に正しく埋め込みをしなければなりません。</li>
                                    <li>ユーザーが作成および実施するプロモーション等に関連してその応募者との間で締結されるすべての契約等は、当事者同士の責任および費用においてなされるものとし、当社は、ユーザーとプロモーション等の応募者との間の契約等について一切の責任を負わないものとします。</li>
                                    <li>ユーザーは、自身が作成および実施するプロモーション等について第三者より問い合わせ、クレーム等がなされた場合、それらに誠意をもって対応する義務を負うものとします。また、自身のプロモーション等に関連して訴訟、紛争等が発生した場合にはすべて自身の責任および費用にてそれらを解決しなればならないものとします。</li>
                                    <li>ユーザーは、プロモーション等に当社が提供するシステムやプログラム等を使用する場合、以下の条項についてエンドユーザーに提示し、同意させるよう努めるものとします。
                                        <ul>
                                            <li>使用許諾条件、範囲等に関する条項</li>
                                            <li>推奨環境を有するPC、ブラウザ等での利用を促す条項</li>
                                            <li>法令等に反する行為の禁止に関する条項</li>
                                            <li>システムに故意に負荷をかける行為の禁止に関する条項</li>
                                            <li>システムの不正な改変等の禁止に関する条項</li>
                                        </ul>
                                    </li>
                                </ol>
                                </p>
                                <h4>第１３条（作成および実施を禁止されるプロモーション等）</h4>
                                <p>
                                <ol>
                                    <li>ユーザーは、以下に該当するプロモーション等を作成、実施してはなりません。
                                        <ul>
                                            <li>第１４条（禁止事項）に該当するまたはその恐れのあるもの</li>
                                            <li>第三者の著作権、商標権その他の知的財産権を侵害するまたはその恐れのあるもの</li>
                                            <li>第三者の営業等を妨害するまたはその恐れのあるもの</li>
                                            <li>性風俗、アダルト関連のもの</li>
                                            <li>宗教団体、政治団体等への勧誘を目的とするもの</li>
                                            <li>その他、当社が不適切であると判断するもの</li>
                                        </ul>
                                    </li>
                                    <li>当社は、ユーザーが前項に該当するプロモーション等を作成または実施したことを把握した場合、当該ユーザーに対して、改善指示、警告等をすることができるものとし、当該指示等に従わない場合は、第１０条の処分を課すことができるものとします。</li>
                                </ol>
                                </p>
                                <h3>第４章　禁止事項</h3>
                                <h4>第１４条（禁止事項）</h4>
                                <p>ユーザーは、本サービスの利用に際して、以下の行為等をしてはなりません。
                                <ol>
                                    <li>本サービスの利用に係る権利等を第三者に譲渡、貸与等する行為</li>
                                    <li>本サービスを第三者に利用させる行為、第三者のために本サービスを利用する行為</li>
                                    <li>本サービスのアカウントを第三者に開示する行為</li>
                                    <li>ハッキング行為、ウィルス送信、スパムメール送信等の当社または第三者に損害を与える可能性のある行為</li>
                                    <li>本サービスを介してプログラム、ソフトウェア等を送受信する行為（本サービスを介して提供されるプログラム等を使用する場合を除きます。）</li>
                                    <li>本サービスに係るプログラム、ソフトウェア等を不正に改造または改変する行為</li>
                                    <li>本サービスに係るプログラム、ソフトウェア等をリバースエンジニアリング、逆コンパイル、または逆アセンブル等の手法によって解析する行為</li>
                                    <li>法令に違反する行為またはその恐れのある行為</li>
                                    <li>公序良俗に反する行為またはその恐れのある行為</li>
                                    <li>虚偽の情報等を送信する行為</li>
                                    <li>当社、他のユーザー、応募者損害を与える行為</li>
                                    <li>当社、他のユーザー、応募者の評判、信用を毀損するまたはその恐れのある行為</li>
                                    <li>当社、他のユーザー、応募者行為</li>
                                    <li>外部サービスの利用規約等に反する行為</li>
                                    <li>本サービスの運営を妨害する行為</li>
                                    <li>その他当が不適切と判断する行為</li>
                                </ol>
                                </p>
                                <h3>第５章　雑則</h3>
                                <h4>第１５条（通知手段）</h4>
                                <p>
                                <ol>
                                    <li>当社からユーザーへの通知方法は、別段に定めのある場合を除き当社からの電子メールの送信またはその他当社が適当と認める方法によって行われるものとします。</li>
                                    <li>電子メールによって行われる通知は、ユーザーが指定した電子メールアドレス宛への発信をもって完了するものとします。ユーザーは、その電子メールアドレスについて迷惑メール対策等をしている場合、その設定を変更し、当社からの通知を受信できるようにしなければなりません。</li>
                                    <li>当社は、ユーザーに通知を行った場合、異議申し立てがない限り、通知日をもってユーザーが同通知の内容に同意したものとみなします。</li>
                                    <li>当社は、連絡先メールアドレスの不備、誤記があったことまたは変更を怠ったこと等ユーザーの故意または過失に起因して当社からの通知が不到達となった場合、同通知についてユーザーが異議なく同意したとみなします。</li>
                                </ol>
                                </p>
                                <h4>第１６条（著作権、知的財産権の所在）</h4>
                                <p>
                                <ol>
                                    <li>本サービスを介してユーザーに対して提供されるコンテンツの著作権その他の知的財産権等は、すべて当社に帰属します。</li>
                                    <li>当社は、ユーザーが第９条第１項の目的にて、および第１３条に違反しない限りにおいて、本サービスを利用する場合に限り、ユーザーに対して、本サービスおよびコンテンツの使用を許諾します。</li>
                                    <li>本規約によりユーザーに対して使用許諾されないすべての権利は、当社が留保します。ユーザーは、当社の知的財産権を侵害してはなりません。</li>
                                    <li>ユーザーがプロモーション等の作成に際して入力した情報やアップロードしたデータ等の著作権およびその他の知的財産権は、すべて当該ユーザーに帰属します。当社は、ユーザーがアップロード等した情報について、</li>
                                </ol>
                                </p>
                                <h4>第１７条（登録情報の取り扱い）</h4>
                                <p>
                                <ol>
                                    <li>当社は、登録情報を本サービスおよび当社が展開する他のサービスの運営および提供の目的のみに利用するものとします。</li>
                                    <li>当社は、登録情報を第三者に開示または提供しないものとします。ただし、以下に該当する場合は、この限りではありません。
                                        <ul>
                                            <li>ユーザーが許諾した場合</li>
                                            <li>ユーザーを特定できない方法にて開示・提供する場合</li>
                                            <li>法令にて開示または提供が認められる場合</li>
                                            <li>法令等に基づいて開示または提供を求められた場合</li>
                                            <li>本サービスの提供の目的のため本サービスの一部または全部を第三者に委託する場合</li>
                                            <li>本サービスの運営を第三者に譲渡する場合</li>
                                        </ul>
                                    </li>
                                    <li>当社は、登録情報のうち、個人情報保護法の「個人情報」に該当する情報について、本サイト上のプライバシーポリシーに基づき管理および使用するものとします。</li>
                                    <li>当社は、ユーザーに対して、収集した個人情報および嗜好情報等を利用して当社が提供する各サービスに関する案内等を送信することができ、ユーザーは、同利用に予め了承するものとします。</li>
                                </ol>
                                </p>
                                <h4>第１８条（損害賠償）</h4>
                                <p>
                                <ol>
                                    <li>ユーザーは、本サービスの利用に関連して自らの故意または過失により当社および第三者に損害を生じせしめた場合、同損害のすべてを賠償する義務を負うものとし、いかなる場合も当社を免責するものとします。</li>
                                    <li>当社は、ユーザーが本サービスの利用に際して損害をこうむった場合、当該損害が1)消費者契約である場合で、且つ2)当社の責に帰すべき事由に起因して現実に発生した、3)直接かつ通常の範囲である場合に限り、同損害を賠償するものとします。ただし、この場合でも、ユーザーによる当社に対する損害賠償限度額は、いかなる場合も当該損害が生じた本サービスの利用料金の１か月分に相当する金額（複数月分を一括で支払っている場合には、その利用料金額をそのプランの有効期間の月数で除した金額とします。）を超えないものとします。</li>
                                </ol>
                                </p>
                                <h4>第１９条（本サービスの一時中断・終了）</h4>
                                <p>
                                <ol>
                                    <li>当社は、本サービスの一部または全部を当社の判断で一時的に中断、変更、終了等することができます。また、自然災害、通信障害、外部サービスのメンテナンス等の不可抗力の発生によっても中断等される場合があります。</li>
                                    <li>当社は、本サービスの一時中断または終了によって直接または間接に生じる会員または第三者の損失または損害に対して、一切の責任を負わないものとします。</li>
                                </ol>
                                </p>
                                <h4>第２０条（合意管轄、準拠法）</h4>
                                <p>
                                <ol>
                                    <li>本規約およびガイドライン等は、すべて日本国法に準拠して解釈されるものとします。</li>
                                    <li>本規約およびガイドライン等に関連して紛争等が発生した場合には、大阪地方裁判所を第一審の専属管轄裁判所とします。</li>
                                </ol>
                                </p>
                                <p>【改定：令和3年7月1日】</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
};

export default TermsOfService;